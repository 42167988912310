@import "./config";
.page-others {
  grid-column-start: 4;
  grid-column-end: 19;
  background-color: $darkmode-background-second;
  height: 80vh;
  margin-top: 10vh;
  border-radius: 1rem;
  overflow: auto;
  transition: $transition-all-ease;

  z-index: 1;
}

@media screen and (max-width: 768px) {
  .page-others {
    grid-column-start: 1;
    grid-column-end: 21;
    height: 100vh;
    margin-top: 0;
    // background: none;
    border-radius: 0;
    overflow-x: hidden;
    // display: inline-block;
    // white-space: normal;
    img {
      display: inline-block;
    }
  }
}
