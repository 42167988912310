@import "./config";

.page-home {
  grid-column-start: 6;
  grid-column-end: 19;
  background: white;
  // height: 80vh;
  height: calc(80vh - 1px); // this removes a 1px height of skeleton's noise at the bottom

  margin-top: 10vh;
  border-radius: 1rem;
  position: relative;
  transition: $transition-all-ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.home-text {
  position: absolute;
  top: 70%;
  left: 40%;
  // transform: translate(50%);
  text-align: right;
  z-index: 9;

  .home-header {
    padding-left: 1rem;
    font-size: 2.4rem;
    font-weight: 300;
    // background-color: $darkmode-background-second-08opacity;
    color: $lightmode-text;
    background-color: white;
    font-family: "Courier Prime";
  }
}

.home-bg-img {
  position: absolute;
  background-size: contain;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 2;
}

.home-subtitle {
  display: block;
  position: absolute;
  bottom: 2vh;
  font-size: 1.6rem;
  font-weight: 100;
  left: 30%;
  color: $darkmode-text-first;
  z-index: 9;
  p {
    background-color: $darkmode-background-first;
    text-align: center;
    position: relative;
    left: -20%;
    padding: 0 0.5rem;
}
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.home-img {
  position: absolute;
  // top: -5rem;
  width: calc(
    80vh * 16 / 9
  ); //https://stackoverflow.com/questions/32070752/css-replace-black-bars-from-youtube-embed
  height: 110%;
  background-size: contain;
  z-index: 1;
}

.no-hover {
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .page-home {
    grid-column-start: 1;
    grid-column-end: 21;
    height: 100vh;
    margin-top: 0;
    border-radius: 0;
    .home-img {
      width: calc(100vh * 16 / 9);
      transform: translateX(-25%);
    }
    .home-bg-img {
      transform: translateX(-25%);
      background-size: cover;
      background-color: $darkmode-text-first;
      top: 0;
      bottom: 0;
    }
    .home-text {
      bottom: 30vh;
      left: 10%;

      h1 {
        font-size: 3rem;
      }
      h3 {
        font-size: 2rem;
      }
    }
    .home-subtitle {
      display: none;
    }

    .typewrite {
      font-size: 1.8rem;
      width: 100%;
    }
  }
}
