@import "./config";

#menu-mobile {
  display: none;
  position: absolute;
  //   right: calc(50vw - 3rem);
  right: 1rem;
  top: 1rem;
  // top: 40vh;
  //   height: 8rem;
  //   background: red;
  // color: $darkmode-text-second;
  // border-radius: 2rem 0 0 2rem;
  //   padding: 0.5rem;
  //   margin: 1rem;
  //   border: 0.5rem solid $darkmode-text-second;
  z-index: 9;
  // background-color: $darkmode-background-second-08opacity;
  // padding: 0.5rem 0 0.5rem 0.5rem;
  // box-shadow: $boxshadow;
  transition: $transition-all-ease;

  //   span {
  //     font-size: 3rem;
  //     padding: 1rem;
  //   }
}

#menu-button {
  display: none;
  font-size: 4rem;
  // border-radius: 2rem 0 0 2rem;

  //   background: $darkmode-text-second;
  //   border-radius: 50%;
  color: $darkmode-text-second;
  transition: $transition-all-ease;
}
#menu-button:active {
  background: $allmodes-text-second;
}
@media screen and (max-width: 768px) {
  #menu-mobile {
    display: block;
    // transform: translateX(-300%);
  }
  #menu-button {
    display: block;
  }
}
