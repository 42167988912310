@import "./config";

.page-myapps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  padding: 2rem;
  transition: $transition-all-ease;
  overflow-x: hidden;
  .myapp-info {
    padding: 3rem;
    padding-top: 3rem;
    transition: $transition-all-ease;
  }
  .myapp-title {
    display: flex;
    div {
      display: inline-block;
      width: 100%;
      margin: 1rem 0;
      border-bottom: solid 2px $darkmode-text-first;
    }
  }

  h2 {
    color: $darkmode-text-first;
    font-family: "Courier Prime";
    font-size: 2.2rem;
    font-weight: bold;
    span {
      font-family: "Roboto";
      font-weight: 300;
      font-size: 2.2rem !important;
      background: transparent;
      color: $darkmode-text-second;
    }
  }
  h4 {
    color: $darkmode-text-second;
    font-size: 1rem;
    font-weight: 300;
    padding: 1rem 0;
  }
  span {
    padding: 0.5rem;
    background-color: $darkmode-background-first;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
  }

  p {
    color: $darkmode-text-second;
    font-size: 1.3rem;
    font-weight: 300;
    padding: 0.75rem 0;
  }

  img {
    aspect-ratio: 1.960 / 1;
    border-radius: 2rem;
    padding: 0.5rem;
    border: solid 1.5px $allmodes-text-second;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transition: $transition-all-ease;
    box-shadow: $boxshadow;
    cursor: pointer;
  }

  img:hover {
    filter: brightness(125%);
    box-shadow: none;
  }

  .img-skeleton {
    aspect-ratio: 1.960 / 1;
    border-radius: 2rem;
    padding: 0.5rem;
    border: solid 1.5px $allmodes-text-second;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transition: $transition-all-ease;
    box-shadow: $boxshadow;
    cursor: pointer;
  }

  .myapp-linkToApp {
    text-decoration: none;
    font-size: 1.5rem;
    padding: 1rem 3rem;
    transition: $transition-all-ease;
    text-align: center;
  }

  .myapp-linkToApp:link,
  .myapp-linkToApp:visited {
    //   border: solid 1px $stonkdown-third;
    border-radius: 0.75rem;
    color: $darkmode-text-first;
  }

  .myapp-linkToApp:hover,
  .myapp-linkToApp:active {
    background: $button-gradient-45-first;
  }
}

@media screen and (max-width: 768px) {
  .page-myapps {
    padding-top: 0;
    .myapp-info {
      padding: 0;
      padding-top: 5rem;
    }
    span {
      display: inline-block;
    }
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    display: inline-block;
  }
}
