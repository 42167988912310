@import "./config";
.page-not-found {
  background: transparent;
  // $darkmode-background-second;
  h1 {
    color: $darkmode-background-second;
    font-size: 22rem;
    transition: $transition-all-ease;
  }
  transition: $transition-all-ease;
}

@media screen and (max-width: 1200px) {
  .page-not-found {
    h1 {
      font-size: 16rem;
    }
  }
}
