@import "./config";

.page-name-container {
  position: absolute;
  top: 5vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 2rem;
  color: $allmodes-text-mid;

  z-index: 99;
  h3 {
    font-family: "Courier Prime";
    font-weight: 400;
  }
  .page-name {
    padding: 0rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .page-name-container {
    display: none;
  }
}
