// darkmode mode
$darkmode-background-first: rgba(27, 30, 42, 1);
$darkmode-background-second: rgba(41, 45, 62, 1);
$darkmode-background-second-08opacity: rgba(41, 45, 62, 0.5);

$darkmode-outline: #3e4251;
$darkmode-highlightmode: #545765;
$darkmode-text-first: rgb(255, 255, 255);
$darkmode-text-second: rgba(255, 255, 255, 0.7);
$darkmode-text-third: rgba(255, 255, 255, 0.4);
$darkmode-text-last: rgba(255, 255, 255, 0.1);

$darkmode-boxshadow-stock: inset 0px 5px 30px 1px rgba(27, 30, 43, 1);

// lightmode mode
$lightmode-background: white;
$lightmode-hover: rgba(0, 0, 0, 0.2);
$lightmode-text: rgba(27, 30, 43, 0.8);

// all modes
$allmodes-text-first: rgba(255, 0, 0, 1);
$allmodes-text-second: rgba(255, 0, 0, 0.8);
$allmodes-text-mid: rgba(255, 0, 0, 0.5);
$allmodes-text-third: rgba(255, 0, 0, 0.2);
$allmodes-text-last: rgba(255, 0, 0, 0.1);

$popup-shadow-bg: rgba(0, 0, 0, 0.8);
$boxshadow: 0px 5px 30px rgba(0, 0, 0, 0.3);
$boxshadow-inset: inset 0px 5px 30px 1px rgba(0, 0, 0, 0.5);
$boxshadow-inset-outline-hover: 0px 0px 0px 1px rgba(255, 0, 0, 0.5);
$boxshadow-inset-outline-active: 0px 0px 0px 2px rgba(255, 0, 0, 1);

// $button-gradient-45: linear-gradient(
//   45deg,
//   #ca4246 16.666%,
//   #e16541 16.666%,
//   #e16541 33.333%,
//   #f18f43 33.333%,
//   #f18f43 50%,
//   #8b9862 50%,
//   #8b9862 66.666%,
//   #476098 66.666%,
//   #476098 83.333%,
//   #a7489b 83.333%
// );

$button-gradient-45-first: linear-gradient(
  45deg,
  rgba(255, 0, 0, 1),
  rgba(255, 0, 100, 1)
);

$button-gradient-45-second: linear-gradient(
  45deg,
  rgba(255, 0, 100, 0.8),
  rgba(255, 0, 0, 0.8)
);

$button-gradient-45-third: linear-gradient(
  45deg,
  rgba(255, 0, 100, 0.2),
  rgba(255, 0, 0, 0.2)
);
$button-gradient-45-last: linear-gradient(
  45deg,
  rgba(255, 0, 100, 0.1),
  rgba(255, 0, 0, 0.1)
);

$transition-all-ease: all 0.3s ease;
