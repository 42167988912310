@import "./config";
@import "./nav";
@import "./pagehome";
@import "./pageothers";
@import "./pageabout";
@import "./pagecontact";
@import "./pagemyapps";
@import "./pagemydesigns";
@import "./pageresume";
@import "./pagenotfound";
@import "./menubutton";
@import "./sociallinks";
@import "./pagename";

@font-face {
  font-family: "Courier Prime";
  font-weight: bold;
  src: url("fonts/CourierPrime-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Courier Prime";
  font-weight: normal;
  src: url("fonts/CourierPrime-Regular.ttf") format("truetype");
}

/* Define Roboto fonts */
@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: url("fonts/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: bold;
  src: url("fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: url("fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url("fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  src: url("fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 100;
  src: url("fonts/Roboto-Thin.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
} /* font-size: 62.5% now means that 1.0 rem = 10px */

body {
  font-family: "Roboto" !important;
  font-style: normal !important;
}

.App {
  position: relative;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  gap: 1rem;
  height: 100vh;
}

.block {
  display: block;
}

.hidden {
  display: none;
}

/////////////////////////////////////////////////////////////////////////////////// NOISE BACKGROUND
// .dark-mode {
//   background-color: $darkmode-background-first;
//   position: relative;
//   &::before {
//     content: "";
//     z-index: 100;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: url("https://i.dlpng.com/static/png/4728213-black-noise-background-texture-pngpublic-domain-icon-park-black-texture-png-2000_2000_preview.png");
//     opacity: 0.1;
//     animation: 1s infinite noise;
//     pointer-events: none;
//     z-index: 0;
//   }
// }

// @keyframes noise {
//   0%,
//   100% {
//     background-position: 0 0;
//   }
//   10% {
//     background-position: -5% -10%;
//   }
//   20% {
//     background-position: -15% 5%;
//   }
//   30% {
//     background-position: 7% -25%;
//   }
//   40% {
//     background-position: 20% 25%;
//   }
//   50% {
//     background-position: -25% 10%;
//   }
//   60% {
//     background-position: 15% 5%;
//   }
//   70% {
//     background-position: 0% 15%;
//   }
//   80% {
//     background-position: 25% 35%;
//   }
//   90% {
//     background-position: -10% 10%;
//   }
// }

/////////////////////////////////////////////////////////////////////////////////// GRADIENT BACKGROUND
.dark-mode {
  position: relative;
  background: linear-gradient(
    29deg,
    #1b1e2a,
    #2a1b25,
    #1b1e2a,
    #1b2a1f,
    #1b1e2a
  );
  background-size: 600% 600%;

  -webkit-animation: GradientAnimator 30s ease infinite;
  -moz-animation: GradientAnimator 30s ease infinite;
  -o-animation: GradientAnimator 30s ease infinite;
  animation: GradientAnimator 30s ease infinite;
}

.btn-main {
  background: linear-gradient(29deg, #ff0000, #ff007d, #ff00ce);
  background-size: 100% 100%;

  -webkit-animation: GradientAnimator 5s ease infinite;
  -moz-animation: GradientAnimator 5s ease infinite;
  -o-animation: GradientAnimator 5s ease infinite;
  animation: GradientAnimator 5s ease infinite;
}

@-webkit-keyframes GradientAnimator {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes GradientAnimator {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-o-keyframes GradientAnimator {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes GradientAnimator {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

hr {
  border: 0;
  height: 2px;
  margin: 2rem 0;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(255, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

::-webkit-scrollbar {
  width: 20px;
  border-width: 5px;
}
::-webkit-scrollbar-track {
}
::-webkit-scrollbar-track-piece {
  background-color: $darkmode-background-first;
  border-color: $darkmode-background-second;
  border-width: 2px 9px 2px 9px;
  border-style: solid;
  margin: 5rem 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 9px;
  background-color: $allmodes-text-first;
  border-color: $darkmode-background-second;
  border-style: solid;
  border-width: 1px 8px 1px 8px;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.page-inactive {
  display: none;
}

@media screen and (max-width: 1200px) {
}

.video-skeleton {
  --bgColor: #fff; /* Default color */
  background: 
    repeating-radial-gradient(#000 0 0.0001%, var(--bgColor) 0 0.0002%) 50% 0/2500px 2500px,
    repeating-conic-gradient(#000 0 0.0001%, var(--bgColor) 0 0.0002%) 60% 60%/2500px 2500px;
  background-blend-mode: difference;
  animation: b 0.2s infinite alternate;
}

@keyframes b {
  100% {
    background-position: 50% 0, 60% 50%;
  }
}
